<template>
  <section class="lost-password">
    <div class="grid-x">
      <div class="cell auto">
        <form @submit.prevent="lostPassword" v-if="newPasswordEmailSent">
          <h4 class="title">Mot de passe oublié</h4>
          <div class="row forgotten-password-section">
            <h4>Un lien vous a été envoyé à l'adresse {{ email }}</h4>
          </div>
          <div class="row">
            <router-link :to="{ name: 'login' }">retour</router-link>
          </div>
        </form>
        <form @submit.prevent="lostPassword" v-else>
          <h4 class="title">Mot de passe oublié</h4>
          <div class="row">
            <p>Vous avez oublié votre mot de passe ou votre identifiant. Saisissez votre email, nous allons vous faire parvenir un message vous permettant de redéfinir votre mot passe ou de récupérer votre identifiant.</p>
          </div>
          <div class="row">
            <app-input
              label="Email"
              v-model="email"
              placeholder="Email..."
              type="email"
              :hasSucceed="isFormInvalid ? 'error' : null"
              :msg="isFormInvalid ? errorMessage : null"
              required />
          </div>
          <div class="row">
            <app-button type="submit">Valider</app-button>
          </div>
          <div class="row forgotten-password-section" v-if="newPasswordEmailSent">
            <h4>Un lien vous a été envoyé à l'adresse {{ email }}</h4>
          </div>
          <div class="row">
            <router-link :to="{ name: 'login' }">retour</router-link>
          </div>
        </form>
      </div>
      <div class="cell auto presentation">
        <div class="blur"></div>
        <div class="content">
          <logo-bird class="logo"/>
          <h6>Le plus court chemin entre vous et votre maison</h6>
          <router-link class="back-link" :to="{ name: 'home' }">
            <app-button theme="secondary">Retour au site</app-button>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import authApi from '@/services/auth';
import logoBird from '@/assets/logos/bird.svg?inline';

export default {
  components: {
    'logo-bird': logoBird,
  },
  data() {
    return {
      email: '',
      errorMessage: '',
      isFormInvalid: false,
      newPasswordEmailSent: null,
    };
  },
  methods: {
    async lostPassword() {
      this.errorMessage = '';
      try {
        await authApi.lostPassword(this.email);
        this.newPasswordEmailSent = true;
      } catch (e) {
        this.isFormInvalid = true;
        if (e.response.status === 400) {
          this.errorMessage = 'Cette adresse ne correspond à aucun compte';
        } else {
          this.errorMessage = 'Une erreur est survenue';
        }
      }
    },
  },
};

</script>

<style lang="sass" scoped>
  .lost-password
    margin-top: 70px
    > .grid-x
      min-height: 80vh
    .cell
      padding: 40px
    .title
      color: black
      font-size: 20px
      font-weight: 500
    .row
      @include row
      width: 100%
    form
      display: flex
      flex-direction: column
      justify-content: center
      height: 100%
      max-width: 400px
      margin: auto
      h4
        color: $body-color
      button
        width: fit-content
        padding-left: 20px
        padding-rigth: 20px
    .presentation
      background-image: url(../../assets/img/pages/background-house.jpeg)
      background-size: cover
      background-position: center
      position: relative
      @media (max-width: 1080px)
        display: none
      .blur
        position: absolute
        width: 100%
        height: 100%
        top: 0
        left: 0
        z-index: 1
        @include black-filter
      .content
        height: 100%
        z-index: 2
        position: relative
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        .logo
          margin-bottom: 20px
      h6
        color: $white
      .back-link
        margin-top: 30px
        margin-bottom: 50px
        text-decoration: none
</style>
